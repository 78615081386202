﻿export default {
    nav: {
        Account: 'Account',
        AddProduct: 'Add product',
        Bags: 'Bags',
        Accessories: 'Accessories',
        ChangePassword: 'Change password',
        Contact: 'Contact',
        CreateAccount: 'Create account',
        EditDetails: 'Edit details',
        ForgotPassword: 'Forgot password?',
        Home: 'Home',
        Login: 'Login',
        Men: 'Men',
        Search: 'Search',
        SignOut: 'SignOut',
        Women: 'Women',
        Menu: 'Menu',
        ContactUs: 'Contact Us',
        OurNewsletter: 'Our Newsletter',
        Subscribe: 'Subscribe',
        FooterResume: 'Butterfly Gabriel Habet is a fashion brand mainly known for its bag and accessory designs for both women and men.',
        NewsletterResume: 'I would like to receive emails from GabrielHabet with updates and special offers of GabrielHabet. I can unsubscribe any time by clicking the unsubscribe link in the email.',
        Orders: 'Orders',
        Privacy_policy: 'Privacy policy',
        Return_policy: 'Return policy',
        Terms_and_conditions: 'Terms and conditions',
        CustomerInfo: 'Customer information'
    },

    products: {
        AddToCart: 'Add to cart',
        Bags: 'Bags',
        Accessories: 'Accessories',
        BuyNow: 'Buy now',
        Men: 'Men',
        Women: 'Women',
        WomenAccessories: 'Women accessories',
        WomenBags: 'Women bags',
        MenAccessories: 'Men accessories',
        MenBags: 'Men bags'
    },

    contact: {
        Title: 'Contact our Support and Sales team',
        Subtitle: 'Our team is happy to answer your questions. Fill out the form and we’ll be in touch as soon as possible.',
        Address: 'Address',
        AddressValue: '73 Somerfield Rd, Manchester M9 8AQ, UK',
        Phone: 'Phone',
        PhoneValue: '+44 161 258 2629',
        Email: 'Email',
        Subject: 'Subject',
        Message: 'Message',
        SendMessage: 'Send Message'
    },

    search: {
        SearchProducts: 'Search products',
        AddToCart: 'Add to cart',
        BuyNow: 'Buy now',
        Categories: 'Categories',
        Gender: 'Gender',
        Men: 'Men',
        Women: 'Women',
        Products: 'Products',
        Bags: 'Bags',
        Accessories: 'Accessories',
        PriceRange: 'Price Range',
        Filter: 'Filter',
        All: 'All'
    },

    checkout: {
        Checkout: 'Checkout',
        BillingDetails: 'Billing Details',
        FirstName: 'First Name',
        LastName: 'Last Name',
        State: 'State / Country',
        StreetAddress: 'Street Address',
        Town: 'Town / City',
        Postcode: 'Postcode / ZIP',
        Phone: 'Phone',
        Email: 'Email Address',
        Subtotal: 'Subtotal',
        Delivery: 'Delivery',
        Total: 'Total',
        PaymentMethod: 'Payment Method',
        Paypal: 'Paypal',
        CreditCard: 'Credit card',
        PlaceOrder: 'Place order',
        MyCart: 'My cart',
        Product: 'Product',
        Price: 'Price',
        Quantity: 'Quantity',
        ProceedToCheckout: 'Proceed to Checkout'
    },

    user: {
        Register: 'Register',
        LogInDetails: 'LogIn Details',
        Email: 'Email',
        Password: 'Password',
        PersonalDetails: 'Personal Details',
        FirstName: 'First Name',
        LastName: 'Last Name',
        State: 'State / Country',
        Town: 'Town / City',
        StreetAddress: 'Street Address',
        Postcode: 'Postcode / ZIP',
        Phone: 'Phone',
        ChangePassword: 'Change password',
        NewPassword: 'New Password',
        ConfirmPassword: 'Confirm Password',
        RecoverPassword: 'Recover password',
        ChangePassword: 'Change password'
    },

    product: {
        ProductDetails: 'Product Details',
        Details: 'Details',
        AddToCart: 'Add to cart',
        BuyNow: 'Buy now',
        AddProduct: 'Add Product',
        Name: 'Name',
        Price: 'Price',
        Description: 'Description',
        Gender: 'Gender',
        Select: 'Select',
        Bags: 'Bags',
        Accessories: 'Accessories',
        Bag: 'Bag',
        Accessory: 'Accessory',
        Men: 'Men',
        Women: 'Women',
        Type: 'Type',
        AddProduct: 'Add product',
        StyleCode: 'Style code',
        Leather: 'Leather',
        Colour: 'Colour',
        Delete: 'Delete product',
        Code: 'Code'
    },

    updateDetails: {
        UpdatePersonalDetails: 'Update personal details',
        UpdateDetails: 'Update Details'
    },

    intro: {
        MP1: 'Many years ago, Gabriel’s career started working as a leather cutter for Italian companies. From design to assembly and product finishing, he went through all the steps required to make leather articles, mastering his abilities in this field over time...',
        P1: 'Many years ago, Gabriel’s career started working as a leather cutter for Italian companies. From design to assembly and product finishing, he went through all the steps required to make leather articles, mastering his abilities in this field over time. Later on, being experienced and passionate, he started working with some of the famous in the field, creating new models for both men and women, contributing through his work to the evolution of leather processing and metal accessories. Then, it came a time when he decided to use this experience in order to create new designs of his own idea under his own new born brand: Butterfly Gabriel Habet.',
        P2: 'His creations are exclusively handmade, designed and produced with an exigency from raw materials which are directly imported from Italy. This highlights the quality and Italian excellence in tanning and leather processing. The following collection aims to satisfy the requirements of enthusiasts of leather articles, delighting them with minimal style elements inspired by modern geometrism but with the ample freedom of defined classicism.',
        P3: 'Thank you for stopping-by and enjoy shopping!'
    },

    order: {
        Total: 'Total',
        OrderNo: 'Order no.',
        PlacedOn: 'Placed on',
        OrderDetails: 'Order details',
        OrderHistory: 'Orders history',
        Delivered: 'Delivered',
        NotDelivered: 'Not delivered',
        UsersOrders: 'Users orders',
        ShowOrders: 'Show orders',
        OrderIsDelivered: 'Order is delivered'
    },

    privacyPolicy: {
        Title: 'Privacy policy',
    },

    termsAndCond: {
        Title: 'Terms and conditions',
    },
}