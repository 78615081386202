﻿export default {
    nav: {
        Account: 'Cont',
        AddProduct: 'Adaugă produs',
        Bags: 'Genți',
        Accessories: 'Accesorii',
        ChangePassword: 'Schimbă parola',
        Contact: 'Contact',
        CreateAccount: 'Creează cont',
        EditDetails: 'Editează detaliile',
        ForgotPassword: 'Ai uitat parola?',
        Home: 'Acasă',
        Login: 'Autentificare',
        Men: 'Bărbați',
        Search: 'Căutare',
        SignOut: 'Deconectare',
        Women: 'Femei',
        Menu: 'Meniu',
        ContactUs: 'Contactați-ne',
        OurNewsletter: 'Newsletter-ul nostru',
        Subscribe: 'Abonează-te',
        FooterResume: 'Butterfly Gabriel Habet este un brand de modă cunoscut în principal pentru design-ul geanților și curelelor atât pentru femei, cât și pentru bărbați.',
        NewsletterResume: 'Aș dori să primesc e-mailuri de la GabrielHabet cu actualizări și oferte speciale. Ma pot dezabona oricand dand click pe linkul de dezabonare din e-mail.',
        Orders: 'Comenzi',
        Privacy_policy: 'Politica de confidențialitate',
        Return_policy: 'Politica de returnare',
        Terms_and_conditions: 'Termeni și condiții',
        CustomerInfo: 'Informații clienți'
    },

    products: {
        AddToCart: 'Adaugă în coș',
        Bags: 'Genți',
        Accessories: 'Accesorii',
        BuyNow: 'Cumpară acum',
        Men: 'Bărbați',
        Women: 'Femei',
        WomenAccessories: 'Accesorii pentru femei',
        WomenBags: 'Genți pentru femei',
        MenAccessories: 'Accesorii pentru bărbați',
        MenBags: 'Genți pentru bărbați'
    },

    contact: {
        Title: 'Contactați echipa noastră de asistență și vânzări',
        Subtitle: 'Echipa noastră este nerăbdătoare să vă răspundă la întrebări. Completați formularul și vom răspunde cât mai curând posibil.',
        Address: 'Adresa',
        AddressValue: 'Sat Țigănași, Comuna Țigănași, Strada Principală, Nr. 126, Județ Iași',
        Phone: 'Telefon',
        PhoneValue: '+40 753 696 163',
        Email: 'Email',
        Subject: 'Subject',
        Message: 'Mesaj',
        SendMessage: 'Trimite mesaj'
    },

    search: {
        SearchProducts: 'Căutați produse',
        AddToCart: 'Adaugă în coș',
        BuyNow: 'Cumpară acum',
        Categories: 'Categorii',
        Gender: 'Sex',
        Men: 'Bărbați',
        Women: 'Femei',
        Products: 'Produse',
        Bags: 'Genți',
        Accessories: 'Accesorii',
        PriceRange: 'Gama de prețuri',
        Filter: 'Filtrează',
        All: 'Toate'
    },

    checkout: {
        Checkout: 'Checkout',
        BillingDetails: 'Detalii de facturare',
        FirstName: 'Prenume',
        LastName: 'Nume',
        State: 'Stat / țară',
        StreetAddress: 'Stradă',
        Town: 'Oraș',
        Postcode: 'Cod poștal / Zip',
        Phone: 'Telefon',
        Email: 'Adresa de email',
        Subtotal: 'Subtotal',
        Delivery: 'Livrare',
        Total: 'Total',
        PaymentMethod: 'Modalitate de plată',
        Paypal: 'Paypal',
        CreditCard: 'Card de credit',
        PlaceOrder: 'Plasați comanda',
        MyCart: 'Coșul meu',
        Product: 'Produs',
        Price: 'Preț',
        Quantity: 'Cantitate',
        ProceedToCheckout: 'Finalizează cumpărăturile'
    },

    user: {
        Register: 'Register',
        LogInDetails: 'Detalii logare',
        Email: 'Email',
        Password: 'Parolă',
        PersonalDetails: 'Detalii personale',
        FirstName: 'Prenume',
        LastName: 'Nume',
        State: 'Stat / țară',
        StreetAddress: 'Stradă',
        Town: 'Oraș',
        Postcode: 'Cod poștal / Zip',
        Phone: 'Telefon',
        ChangePassword: 'Schimbă parola',
        NewPassword: 'Parolă nouă',
        ConfirmPassword: 'Confirmă parola',
        RecoverPassword: 'Recuperare parolă',
        ChangePassword: 'Schimbați parola'
    },

    product: {
        ProductDetails: 'Detalii produs',
        Details: 'Detalii',
        AddToCart: 'Adauga in cos',
        BuyNow: 'Cumpărați acum',
        AddProduct: 'Adăugați produs',
        Name: 'Nume',
        Price: 'Preț',
        Description: 'Descriere',
        Gender: 'Sex',
        Select: 'Selectați',
        Bags: 'Genți',
        Accessories: 'Accesorii',
        Bag: 'Geantă',
        Accessory: 'Accesoriu',
        Men: 'Bărbați',
        Women: 'Femei',
        Type: 'Tip',
        AddProduct: 'Adaugă produs',
        StyleCode: 'Cod produs',
        Leather: 'Piele',
        Colour: 'Culoare',
        Delete: 'Șterge produsul',
        Code: 'Cod'
    },

    updateDetails: {
        UpdatePersonalDetails: 'Actualizare date personale',
        UpdateDetails: 'Actualizați datele personale'
    },

    intro: {
        MP1: 'Acum mulți ani, Gabriel și-a început cariera lucrând ca și croitor de piele pentru companii italiene. De la design, la asamblare și până la finalizare a trecut prin toți pașii necesari pentru a face articole din piele, excelând în domeniu de-a lungul timpului...',
        P1: 'Acum mulți ani, Gabriel și-a început cariera lucrând ca și croitor de piele pentru companii italiene. De la design, la asamblare și până la finalizare a trecut prin toți pașii necesari pentru a face articole din piele, excelând în domeniu de-a lungul timpului. Mai târziu, dată fiindu-i experiența și pasiunea, a început să lucreze cu firme celebre în domeniu, creând modele pentru ambele genuri, contribuind prin munca sa la evoluția procesării pielii și a diversificării accesoriilor din metal. Apoi, a venit un timp când a decis să-și folosească experiența pentru a crea modele noi, definind cu talent și meticulozitate un nou brand, „Butterfly Gabriel Habet”.',
        P2: 'Creațiile lui sunt făcute exclusiv manual, concepute și produse cu exigență din material brut, importat direct din Italia. Acest lucru evidențiază calitatea și excelența italiană în procesarea pielii. Colecția ce urmează are intenția de a satisface cerințele amatorilor de articole din piele, încântându-i cu elemente ale stilului minimalist, inspirate din geometrismul modern, dar cu ampla libertate a clasicismului definit.',
        P3: 'Vă mulțumim pentru atenția acordată și vă dorim spor la cumpărături!'
    },

    order: {
        Total: 'Total',
        OrderNo: 'Comanda nr.',
        PlacedOn: 'Plasată pe',
        OrderDetails: 'Detalii comandă',
        OrderHistory: 'Istoric comenzi',
        Delivered: 'Livrate',
        NotDelivered: 'Nelivrate',
        UsersOrders: 'Comenzile utilizatorilor',
        ShowOrders: 'Afișează comenzile',
        OrderIsDelivered: 'Comanda este livrată'
    },

    privacyPolicy: {
        Title: 'Politica de confidențialitate',
    },

    termsAndCond: {
        Title: 'Termeni și condiții',
    },
}